html {
    font-family: sans-serif;
    font-size: 12px;
  }
  
  body {
    margin: 0;
  }
  
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }
  
  .List {
    border: 1px solid #d9dddd;
  }
  
  .ListItemEven,
  .ListItemOdd {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  